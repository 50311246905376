import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/stores/user'
import { useToast } from 'vue-toast-notification'
import { useLoadingStore } from '@/stores/loading'

import payments from './modules/payments'
import shop from './modules/shop'
import services from './modules/services'
import auth from './modules/auth'
import recharges from './modules/recharges'
import gift_cards from './modules/gift_cards'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior (to, from, savedPosition) {
    return {
      top:50,
      behavior: 'smooth',
      el: to.hash == '' ? 'body' : to.hash
    };
  },
  routes: [
    auth,
    {
      path:'/',
      component: () => import('@/views/Layout.vue'),
      children:[
        payments,
        shop,
        services,
        recharges,
        gift_cards,
        {
          path: '/',
          name: 'home',
          component: () => import('@/views/public/HomeView/HomeView.vue')
        },
        {
          path: '/about-us',
          name: 'about-us',
          component: () => import('@/views/public/AboutUs/AboutUs.vue')
        }, 
        {
          path: '/profile',
          name: 'profile',
          component: () => import('@/views/Profile/Profile.vue'),
          meta: {
            requiresAuth: true,
            pageTitle: 'Perfil'
          }
        },
      ]
    },
    {
      path: '/contact-us',
      name: 'contact-us',
      component: () => import('@/views/ContactUs.vue')
    },
  ]
})


router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();
  const loadingStore = useLoadingStore();
  const toast = useToast();

  document.title = 'JanetTravels';
  if(to.meta.pageTitle)
    document.title += ` | ${to.meta.pageTitle}`;

  loadingStore.startLoading();
  if (userStore.isSessionLoading) {
    /* if session is currently refreshing with refresh_token edge function, wait until is done! */
    await new Promise(async (resolve) => {
      while (true) {
        await new Promise((resolve2) => setTimeout(resolve2, 100));
        if (!userStore.isSessionLoading) {
          resolve();
          break;
        }
      }
    })
  }
  const isAuthenticated = await userStore.isLoadedProfileData();
  loadingStore.endLoading();

  if(to.meta.requiresAuth && !isAuthenticated){
    toast.info('Por favor inicia sesión');
    next({name:'login', query: { redirect: to.fullPath }}) 
  } else {
    next()
  }
})

export default router
