

export const environment = {
  // SUPABASE_URL: 'https://api.dara-services.org',
  // ANON_KEY: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVjYndndnZhdm5pdG5qYWVmaHljIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTg2NzkzNjYsImV4cCI6MjAxNDI1NTM2Nn0.JIzroGt5_-twUtdq8ty7Wo7lmkShPJRe2UQRi7yLRBI'

  SUPABASE_URL: 'https://api.dara-services.com',
  ANON_KEY: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9rcnl4bGZld3J6emdzcGd2ZWR0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTE1MDI4NjgsImV4cCI6MjAwNzA3ODg2OH0.oyNGdCdlQjEOss8SgCJvfMjgt2Aj9WDyxzhlFmmyjO0'
}

//// develop
// export const GIFT_CARDS_TENANT_ID ="2d6d76cf-b42b-4011-b649-9cd414aaf457"
// export const SHOP_TENANT_ID ="31e652cc-9702-48a4-9f1d-3ed3530a7607"
// export const RECHARGES_TENANT_ID ="510b7de5-15da-4929-8c46-7911c59cbf39"
// export const TENANT_ID ="66c2ab43-823d-4520-a570-28ff02420bd1"
// export const RECHARGES_NAUTA_TENANT_ID ="78493c87-79a5-41ca-9935-387c942f571e"

//// prod
export const GIFT_CARDS_TENANT_ID ="56a24c22-358a-4534-90eb-f558f5ccd923"
export const SHOP_TENANT_ID ="8bfb4803-7bdd-4254-b697-2083bad07085"
export const RECHARGES_TENANT_ID ="79cf1783-302e-4d1f-9fb0-50a2a259c298"
export const TENANT_ID ="ed1c597b-3cd5-47f8-806c-70411f0ccced"
export const RECHARGES_NAUTA_TENANT_ID ="023b1ee7-14a2-4e01-928e-662d5dd6246e"


export const CHAPORT_ID = '65c52f90119e5b03160ef70c'

export const SENTRY_ENV = 'development'
