import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

/* 
  import * as Sentry from "@sentry/vue";
  import { SupabaseIntegration } from '@supabase/sentry-js-integration';
  import { supabaseClient } from './supabase' 
*/

import App from './App.vue'
import router from './router'

import './index.css'
import 'vue-toast-notification/dist/theme-sugar.css';

import { addIcons } from "oh-vue-icons";
import { IoArrowBackCircle, FaKey, FaMoneyBillWave, IoLogOut, IoClose, RiInstagramFill, BiFacebook, RiWhatsappFill, BiChevronDown, HiArrowRight, FcGoogle, BiApple, CoFacebookF, RiLinkedinFill, BiEye, BiEyeSlash, BiLayoutSidebar, HiSolidSearch } from "oh-vue-icons/icons";
/* import { SENTRY_ENV } from './environment'; */
addIcons( IoArrowBackCircle, FaKey, FaMoneyBillWave, IoLogOut,IoClose,  RiInstagramFill, BiFacebook, RiWhatsappFill, BiChevronDown, HiArrowRight, FcGoogle, BiApple, CoFacebookF,RiLinkedinFill, BiEye, BiEyeSlash, BiLayoutSidebar, HiSolidSearch );

const app = createApp(App)

app.use(router)
app.use(createPinia())

/* Sentry.init({
  app,
  dsn: "https://ac4a8dae506eb1c3d510b7a4634ebca7@o4507143191658496.ingest.us.sentry.io/4507143194542080",
  environment: SENTRY_ENV,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    new SupabaseIntegration(supabaseClient,{
      tracing: true,
      breadcrumbs: true,
      errors: true
    }),
  ],
}); */

app.mount('#app')
