<script setup>
  import Loading from './components/Loading.vue';
  import { initChaport } from './chaport';

  initChaport()
</script>

<template>
  <div id="app">
    <loading />
    <router-view v-slot="{ Component }">
      <transition name="viewfade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>

  </div>
</template>



<style>

.viewfade-enter-from, .viewfade-leave-to {
  opacity: 0;
}
.viewfade-enter-to,.viewfade-leave-from{
  opacity: 1;
}
.viewfade-enter-active,
.viewfade-leave-active {
  transition: opacity 0.5s;
}


</style>
